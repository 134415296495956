html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'IBM Plex Serif', 'serif';
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 3rem;
  
}

